
.flex-center {
  @extend .d-flex, .justify-content-center, .align-items-center;
}

.cursor-pointer {
  cursor: pointer;
}

.row {
  @extend .m-0, .w-100;
}