.header-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  @extend .cursor-pointer;
}

.navbar {
  .nav-link {
    &.active {
      color: $primary;
    }
  }
}
